<template>
    <ODropdown>
        <template #default="pinScope">
            <slot v-bind="pinScope">
                <button class="dropdown-item" href="javascript:void(0)" :ref="pinScope.target"
                    @click="pinScope.open">{{$t('Expand To Level')}}
                    <i class="bi bi-caret-right-fill float-end"></i>    
                </button>
            </slot>
        </template>
        <template #dropdown="pinScope">
            <div :ref="pinScope.container" class="card shadow dropdown-menu rounder-0">
                <button class="dropdown-item" v-for="level in treeifyLevelsArr" :key="level"
                    @click="()=>{ treeifyExpandTo(level); closeFn ? closeFn() : pinScope.close();}">
                    <i class="bi bi-caret-down opacity-0"></i>
                    {{$t('Level')}} <span class="text-decoration-underline">{{level+1}}</span>
                </button>
                <button class="dropdown-item"
                    @click="()=>{ treeifyExpandTo(deepestLevel); closeFn ? closeFn() : pinScope.close();}">
                    <i class="bi bi-caret-down"></i>
                    {{$t('All Levels')}}
                </button>
            </div>
        </template>
    </ODropdown>
</template>

<script lang="ts">
import type DataObject from 'o365.modules.DataObject.ts';

export interface ITreeifyLevelsDropdownProps {
    dataObject: DataObject,
    closeFn?: () => void,
};
</script>

<script setup lang="ts">
import ODropdown from 'o365.vue.components.DropDown.vue';
import { computed } from 'vue';

const props = defineProps<ITreeifyLevelsDropdownProps>();

const deepestLevel = computed(() => props.dataObject.treeify?.deepestLevel ?? 0);
const treeifyLevelsArr = computed(() => {
    const levels = [];
    for (let i=0; i < deepestLevel.value; i++) {
        levels.push(i);
    }
    return levels;
});

function treeifyExpandTo(level) {
    props.dataObject?.treeify?.expandToLevel(level);
}

</script>